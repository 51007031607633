import { request } from "../utils/axios/request";

// 收货地址列表
export const $list = async () => {
  return await request.post("/address/list");
};

// 新增收货地址
export const $add = async (params) => {
  return await request.post("/address/add", { ...params });
};
