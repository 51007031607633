import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { List, Image, Button, Empty, Flex, Tag, Divider, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { ossBaseURL } from "../../../config/config";
import { $list } from "../../../api/orderAPI";

const parseType = function (type) {
  if (type === 1) {
    return <Tag color="">普通版</Tag>;
  }
  if (type === 2) {
    return <Tag color="">模板声音AI版</Tag>;
  }
  if (type === 3) {
    return <Tag color="">声音克隆AI版</Tag>;
  }
};

const parseTouch = function (touch) {
  return touch === 2 ? <Tag color="">有触碰</Tag> : <Tag color="">无触碰</Tag>;
};

const parseStatus = function (status) {
  if (status === 1) {
    return (
      <Tag color="blue" bordered={false}>
        待确认
      </Tag>
    );
  }
  if (status === 2) {
    return (
      <Tag color="volcano" bordered={false}>
        待支付
      </Tag>
    );
  }
  if (status === 3) {
    return (
      <Tag color="green" bordered={false}>
        已支付
      </Tag>
    );
  }
};

export default function ListMobile() {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      more: true,
    },
  });

  const fetchData = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let data = await $list({
      current: tableParams.pagination.current,
      size: tableParams.pagination.pageSize,
    });
    if (data.code === 10000) {
      // console.log(data.data);
      if (data.data === null) {
        setLoading(false);
        return;
      }
      setList([...list, ...data.data.list]);
      setLoading(false);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: data.data.page.more
            ? data.data.page.current + 1
            : data.data.page.current,
          more: data.data.page.more,
        },
      });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="scrollable" className="data-wrap">
      <InfiniteScroll
        dataLength={list.length}
        next={fetchData}
        hasMore={tableParams.pagination.more}
        loader={
          <Skeleton
            avatar
            title={false}
            active
            loading={loading}
            paragraph={{
              rows: 2,
            }}
            style={{ padding: "12px 12px 0" }}
          />
        }
        endMessage={
          <Divider
            plain
            style={{
              color: "gray",
              marginTop: "0",
              padding: "0 24px",
              fontSize: "12px",
              display: tableParams.pagination.more ? "none" : "",
            }}
          >
            没有更多的数据了
          </Divider>
        }
        scrollableTarget="scrollable"
      >
        <List
          dataSource={list}
          locale={{
            emptyText: (
              <Empty description="暂无数据">
                <Button
                  type="primary"
                  onClick={() => {
                    navigate("/custom");
                  }}
                >
                  前往定制
                </Button>
              </Empty>
            ),
          }}
          renderItem={(item, index) => (
            <List.Item
              className="list-item"
              key={item.code}
              onClick={() => {
                navigate("/detail?code=" + item.code);
              }}
            >
              <Flex className="item-wrap">
                <Flex className="item-left" flex={1} align="center">
                  <Image
                    className="left-image"
                    src={ossBaseURL + item.image}
                    preview={false}
                  />
                </Flex>
                <Flex className="item-right" flex={10} vertical>
                  <Flex className="right-row" justify="space-between">
                    <div style={{ color: "#1677ff" }}>{item.code}</div>
                    <div>
                      {/* <Tag color="blue-inverse">待确认</Tag> */}
                      {parseStatus(item.status)}
                    </div>
                  </Flex>
                  <Flex className="right-row" justify="space-between">
                    <div>
                      {/* <Tag color="geekblue">模板声音AI版</Tag> */}
                      {parseType(item.type)}
                      {/* <Tag color="purple">有触碰</Tag> */}
                      {parseTouch(item.touch)}
                    </div>
                    <div className="row-quantity">x{item.quantity}</div>
                  </Flex>
                  <Flex className="right-row" justify="space-between">
                    <div className="row-price">¥{item.price}/套</div>
                    <div className="row-amount">¥{item.amount}</div>
                  </Flex>
                </Flex>
              </Flex>
            </List.Item>
          )}
        ></List>
      </InfiniteScroll>
    </div>
  );
}
