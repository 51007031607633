import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Radio,
  Checkbox,
  Row,
  Col,
  Button,
  Upload,
  Image,
  InputNumber,
  Cascader,
  message,
} from "antd";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import {
  ossBaseURL,
  customImageURL,
  customVoiceURL,
  customKnowledgeURL,
  ossVoiceURL,
  role as roleList,
} from "../../../config/config";
import { setFieldError, clearFieldError } from "../../../utils/form";
import { isMobile, removeToken } from "../../../utils/func";
import { $create } from "../../../api/orderAPI";
import { trim } from "../../../utils/func";
import { VOICE } from "../../../config/voice";
import "../common.scss";

const DEFAULT_TYPE = 2;

// Form.Item + Upload 数据转换
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

// 自定义表单数据处理
let postData = {
  type: "",
  touch: "",
  incar: 1,
  image: "",
  view: "",
  voice_type: "",
  cid: "",
  role: "",
  knowledge_file: [],
  knowledge_url: [],
  quantity: 1,
};

const setImageAndView = (image, view) => {
  postData.image = image;
  postData.view = view;
};

const clearImageAndView = () => {
  postData.image = "";
  postData.view = "";
};

const setCid = (cid) => {
  postData.cid = cid;
};

const clearCid = () => {
  postData.cid = "";
};

const setKnowledgeFile = (name) => {
  postData.knowledge_file.push(name);
};

const removeKnowledgeFile = (name) => {
  postData.knowledge_file = postData.knowledge_file.filter((item) => {
    return item !== name;
  });
};

export default function Custom() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  // 定制类型 state
  const [type, setType] = useState(DEFAULT_TYPE);
  // 模板声音 ref
  const voiceTypeAudioRef = useRef(null);
  // 原图上传 state
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [isViewShow, setIsViewShow] = useState(false);
  const [viewSrc, setViewSrc] = useState();
  // 原音上传 state
  const [voiceUploadLoading, setVoiceUploadLoading] = useState(false);
  const [isAudioShow, setIsAudioShow] = useState(false);
  const [audioSrc, setAudioSrc] = useState();
  // 知识库上传 state
  const [knowledgeUploadLoading, setKnowledgeUploadLoading] = useState(false);

  // 图片类型检测（不会触发 Upload 的 onChange 事件）
  const beforeImageUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("仅能上传 JPG/PNG 格式的图片");
      clearImageAndView();
      setIsViewShow(false);
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error("图片最大 20MB!");
      clearImageAndView();
      setIsViewShow(false);
    }
    return isJpgOrPng && isLt2M;
  };

  // 音频类型检测（不会触发 Upload 的 onChange 事件）
  const beforeVoiceUpload = (file) => {
    const isAudio =
      file.type === "audio/midi" ||
      file.type === "audio/mpeg" ||
      file.type === "audio/ogg" ||
      file.type === "audio/x-m4a" ||
      file.type === "audio/x-realaudio";
    if (!isAudio) {
      message.error("仅能上传音频文件");
      clearCid();
      setIsAudioShow(false);
    }
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      message.error("文件最大 50MB!");
      clearCid();
      setIsAudioShow(false);
    }
    return isAudio && isLt2M;
  };

  // 原图上传
  const handleImageUpload = (info) => {
    clearFieldError(form, "image");
    clearImageAndView();
    setIsViewShow(false);
    if (info.file.status === "uploading") {
      setImageUploadLoading(true);
      return;
    }
    setImageUploadLoading(false);
    if (info.file.status === "done") {
      if (info.file.response.code !== 10000) {
        message.error(
          "生成失败，" +
            info.file.response.msg +
            "。如遇问题，请联系 4008-123-002 咨询。"
        );
        checkTokenExpires(info.file.response.code);
        return;
      }
      setImageAndView(
        info.file.response.data.image,
        info.file.response.data.view
      );
      // todo: 永祥自己的服务器返回的直接是 url，后期可能需要改造成 oss，如下注释
      // setViewSrc(ossBaseURL + info.file.response.data.view);
      setViewSrc(info.file.response.data.view);
      setIsViewShow(true);
    }
    if (info.file.status === "error") {
      message.error(
        "生成失败，AI生图服务器响应超时。如遇问题，请联系 4008-123-002 咨询。"
      );
    }
    if (info.file.status === "removed") {
      // nothing todo
    }
  };

  // 原音上传
  const handleVoiceUpload = (info) => {
    clearFieldError(form, "voice");
    clearCid();
    setIsAudioShow(false);
    if (info.file.status === "uploading") {
      setVoiceUploadLoading(true);
      return;
    }
    setVoiceUploadLoading(false);
    if (info.file.status === "done") {
      if (info.file.response.code !== 10000) {
        message.error("克隆失败，" + info.file.response.msg);
        checkTokenExpires(info.file.response.code);
        return;
      }
      setCid(info.file.response.data.cid);
      setAudioSrc(ossBaseURL + info.file.response.data.audio);
      setIsAudioShow(true);
    }
    if (info.file.status === "removed" || info.file.status === "error") {
      // nothing todo
    }
  };

  // 知识库上传
  const handleKnowledgeUpload = (info) => {
    if (info.file.status === "uploading") {
      setKnowledgeUploadLoading(true);
      return;
    }
    setKnowledgeUploadLoading(false);
    if (info.file.status === "done") {
      if (info.file.response.code !== 10000) {
        message.error("上传失败，" + info.file.response.msg);
        checkTokenExpires(info.file.response.code);
        return;
      }
      setKnowledgeFile(info.file.response.data);
    }
    if (info.file.status === "removed") {
      removeKnowledgeFile(info.file.response.data);
    }
    if (info.file.status === "error") {
      // nothing todo
    }
  };

  // 上传接口 token 过期处理
  const checkTokenExpires = (code) => {
    if (code === 60000 || code === 40000) {
      removeToken();
      navigate("/");
    }
  };

  // 表单提交
  const handleFormSubmit = async () => {
    let validPass = true;
    postData.type = form.getFieldValue("type");
    postData.touch = form.getFieldValue("touch");
    postData.quantity = form.getFieldValue("quantity");
    validPass = validImage() ? validPass : false;
    validPass = validCid() ? validPass : false;
    validPass = validVoiceType() ? validPass : false;
    parseRole();
    parseKnowledgeUrl();
    // 验证通过，提交表单
    if (validPass) {
      // console.log(postData);
      let data = await $create(postData);
      if (data.code === 10000) {
        navigate("/detail?code=" + data.data);
      } else {
        message.error(data.msg);
      }
    }
  };

  const validImage = () => {
    if (!postData.image || !postData.view) {
      setFieldError(form, "image", "请先上传原图");
      return false;
    }
    return true;
  };

  const validCid = () => {
    if (postData.type === 3 && !postData.cid) {
      setFieldError(form, "voice", "请先上传原音");
      return false;
    }
    return true;
  };

  const validVoiceType = () => {
    const voice_type = form.getFieldValue("voice_type");
    if (postData.type === 2 && !voice_type) {
      setFieldError(form, "voice_type", "请选择模板声音");
      return false;
    }
    postData.voice_type = voice_type;
    return true;
  };

  const parseRole = () => {
    const roles = form.getFieldValue("role");
    if (roles) {
      postData.role = roles.join(",");
    }
  };

  const parseKnowledgeUrl = () => {
    postData.knowledge_url = [];
    if (form.getFieldValue("type") === 1) {
      return;
    }
    const urls = form.getFieldValue("url");
    if (urls) {
      postData.knowledge_url = trim(urls, ",").split(",");
    }
  };

  return (
    <div className="form-wrap">
      <Form
        className="form-right"
        form={form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 13,
          offset: 1,
        }}
        layout="horizontal"
        initialValues={{
          type: DEFAULT_TYPE,
          touch: 1,
          quantity: 1,
        }}
      >
        <Form.Item
          label="定制类型"
          name="type"
          onChange={() => setType(form.getFieldValue("type"))}
        >
          <Radio.Group size={isMobile() ? "small" : "middle"}>
            <Radio.Button key={1} value={1}>
              普通版
            </Radio.Button>
            <Radio.Button key={2} value={2}>
              模板声音AI版
            </Radio.Button>
            <Radio.Button key={3} value={3}>
              声音克隆AI版
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="触碰交互"
          name="touch"
          tooltip={{
            title:
              type !== 1
                ? "主人，选择触碰交互后，我能响应主人的触碰，还能唤醒对话交互哦。"
                : "主人，选择触碰交互后，我能响应主人的触碰。",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Radio.Group size={isMobile() ? "small" : "middle"}>
            <Radio.Button key={1} value={1}>
              无触碰交互
            </Radio.Button>
            <Radio.Button key={2} value={2}>
              有触碰交互
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="形象定制"
          name="image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          tooltip={{
            title:
              "主人，请上传含有人物面部信息特征的图片为我定制形象吧。支持 jpg、png 等格式。",
            icon: <InfoCircleOutlined />,
          }}
          required
        >
          <>
            <Upload
              name="image"
              action={customImageURL}
              listType="picture-card"
              maxCount={1}
              showUploadList={{ showPreviewIcon: false }}
              beforeUpload={beforeImageUpload}
              onChange={handleImageUpload}
              headers={{ Authorization: localStorage.getItem("token") }}
              disabled={imageUploadLoading ? true : false}
            >
              <button
                style={{
                  border: 0,
                  background: "none",
                }}
                type="button"
              >
                {imageUploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>上传原图</div>
              </button>
            </Upload>
            <div
              className="view-wrap"
              style={{ display: isViewShow ? "" : "none" }}
            >
              <Image name="view" height="61%" width="94%" src={viewSrc} />
            </div>
          </>
        </Form.Item>

        <Form.Item
          label="模板声音（选择试听）"
          name="voice_type"
          tooltip={{
            title: "主人，请为我选择一款声音，我将使用该音色与您语音互动。",
            icon: <InfoCircleOutlined />,
          }}
          style={{ display: type === 2 ? "" : "none" }}
          onChange={() => clearFieldError(form, "voice_type")}
          required
        >
          <>
            <Cascader
              options={VOICE}
              placeholder="主人，请为我选择一款声音。"
              onChange={(item) => {
                clearFieldError(form, "voice_type");
                if (voiceTypeAudioRef.current) {
                  voiceTypeAudioRef.current.pause();
                }
                voiceTypeAudioRef.current.src = ossVoiceURL + item[1];
                voiceTypeAudioRef.current.play();
                form.setFieldValue("voice_type", item[1]);
              }}
              allowClear={false}
              style={{ width: "75%" }}
            />
            <audio ref={voiceTypeAudioRef} />
          </>
        </Form.Item>

        {/* <Form.Item
          label="模板声音"
          name="voice_type"
          tooltip={{
            title: "主人，请为我选择一款声音，我将使用该音色与您语音互动。",
            icon: <InfoCircleOutlined />,
          }}
          style={{ display: type === 2 ? "" : "none" }}
          onChange={() => clearFieldError(form, "voice_type")}
          required
        >
          <Radio.Group size={isMobile() ? "small" : "middle"}>
            {voiceType.map((item) => (
              <Radio.Button key={item.value} value={item.value}>
                {item.name}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item> */}

        <Form.Item
          label="声音克隆"
          name="voice"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          tooltip={{
            title:
              "主人，请上传一段包含人物声音的音频，可以是阅读任何文字的录音。推荐时长不低于15秒，我能更好的还原该音色。支持 mp3、ogg、m4a 等格式。",
            icon: <InfoCircleOutlined />,
          }}
          style={{ display: type === 3 ? "" : "none" }}
          required
        >
          <>
            <Upload
              name="voice"
              action={customVoiceURL}
              listType="picture-card"
              maxCount={1}
              showUploadList={{ showPreviewIcon: false }}
              beforeUpload={beforeVoiceUpload}
              onChange={handleVoiceUpload}
              headers={{ Authorization: localStorage.getItem("token") }}
              disabled={voiceUploadLoading ? true : false}
            >
              <button
                style={{
                  border: 0,
                  background: "none",
                }}
                type="button"
              >
                {voiceUploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>上传原音</div>
              </button>
            </Upload>
            <div
              className="view-wrap"
              style={{ display: isAudioShow ? "" : "none" }}
            >
              <audio controls src={audioSrc} />
            </div>
          </>
        </Form.Item>

        <Form.Item
          label="人设定制"
          name="role"
          tooltip={{
            title:
              "主人，请为我选择个性人设，它将赋予我相应的人物设定、说话语气、性格特征、情绪特点等。",
            icon: <InfoCircleOutlined />,
          }}
          style={{ display: type !== 1 ? "" : "none" }}
        >
          <Checkbox.Group>
            <Row>
              {roleList.map((item) => (
                <Col key={item}>
                  <Checkbox value={item} style={{ lineHeight: "32px" }}>
                    {item}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          label="知识库定制（本地）"
          name="knowledge"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          tooltip={{
            title:
              "主人，请为我增加更多关于人物背景的文本信息，什么内容都可以哦。内容越多，我的人物表达会越精准。支持 excel、word、pdf、text 等文件格式。",
            icon: <InfoCircleOutlined />,
          }}
          style={{ display: type !== 1 ? "" : "none" }}
        >
          <>
            <Upload
              name="knowledge"
              action={customKnowledgeURL}
              listType="text"
              showUploadList={{ showPreviewIcon: false }}
              onChange={handleKnowledgeUpload}
              headers={{ Authorization: localStorage.getItem("token") }}
              disabled={knowledgeUploadLoading ? true : false}
            >
              <Button
                icon={
                  knowledgeUploadLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <UploadOutlined />
                  )
                }
              >
                上传本地知识库
              </Button>
            </Upload>
          </>
        </Form.Item>

        <Form.Item
          label="知识库定制（在线）"
          name="url"
          tooltip={{
            title:
              '同本地知识库一样，主人也可以将包含文本数据的 URL 复制进来，我会智能抓取相关信息继续扩充知识库。多个 URL 请使用 "," 分隔。',
            icon: <InfoCircleOutlined />,
          }}
          style={{
            display: type !== 1 ? "" : "none",
            paddingRight: isMobile() ? "26px" : "",
          }}
        >
          <Input.TextArea
            placeholder="www.example1.com,www.example2.com,..."
            autoSize={{
              minRows: 4,
              maxRows: 10,
            }}
          />
        </Form.Item>

        <Form.Item label="定制数量" name="quantity">
          <InputNumber
            min={1}
            onChange={(value) => {
              form.setFieldValue("quantity", value);
            }}
          />
          <span>&nbsp;套</span>
        </Form.Item>

        <Button
          className="operation-button"
          size="large"
          type="primary"
          block
          onClick={handleFormSubmit}
        >
          去 定 制
        </Button>
      </Form>
    </div>
  );
}
