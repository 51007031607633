import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle="对不起，您访问的页面不存在！"
      extra={
        <Button type="primary" onClick={() => navigate("/")}>
          返回首页
        </Button>
      }
      style={{
        height: "100vh",
        backgroundColor: "white",
        paddingTop: "24vh",
      }}
    />
  );
}
