import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Form, Input, Space, message } from "antd";
import { $sms, $login } from "../../api/userAPI";
import { mobilePattern, smsCodePattern } from "../../utils/regex";
import { setFieldError, clearFieldError } from "../../utils/form";
import { isMobile, setToken } from "../../utils/func";
import "./index.scss";

const SMS_COUNTDOWN_TIME = 60;

export default function Index() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSmsDisabled, setIsSmsDisabled] = useState(true);
  const [isSmsSuccess, setIsSmsSuccess] = useState(false);
  const [smsCountdownMsg, setSmsCountdownMsg] = useState();
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isValidCode, setIsValidCode] = useState(false);

  // 开始定制
  const handleCustom = function (e) {
    if (localStorage.getItem("token")) {
      navigate("/custom");
    }
    // 未登录，弹出登录框
    setIsModalOpen(true);
  };

  // 手机号输入框
  const handlePhoneChange = () => {
    const phone = form.getFieldValue("phone");
    if (mobilePattern.test(phone)) {
      setIsValidPhone(true);
      setIsSmsDisabled(false);
      clearFieldError(form, "phone");
    } else {
      setIsValidPhone(false);
      setIsSmsDisabled(true);
      phone === ""
        ? clearFieldError(form, "phone")
        : setFieldError(form, "phone", "请输入正确的手机号");
    }
  };

  // 验证码输入框
  const handleCodeChange = () => {
    const code = form.getFieldValue("code");
    if (smsCodePattern.test(code)) {
      setIsValidCode(true);
      clearFieldError(form, "code");
    } else {
      setIsValidCode(false);
      code === ""
        ? clearFieldError(form, "code")
        : setFieldError(form, "code", "请输入 6 位验证码");
    }
  };

  // 发送短信
  const handleSmsSend = async () => {
    setIsSmsDisabled(true);
    let data = await $sms({ username: form.getFieldValue("phone") });
    if (data.code === 10000) {
      setIsSmsSuccess(true);
      setSmsCountdownMsg("重新发送(" + SMS_COUNTDOWN_TIME + ")");
      smsCountDown();
    } else {
      message.error(data.msg, 5);
      setIsSmsDisabled(false);
    }
  };

  // 发送短信倒计时器
  const smsCountDown = () => {
    let i = SMS_COUNTDOWN_TIME;
    const cd = setInterval(() => {
      if (i <= 1) {
        clearInterval(cd);
        setIsSmsSuccess(false);
        setIsSmsDisabled(false);
      }
      i--;
      setSmsCountdownMsg("重新发送(" + i + ")");
    }, 1000);
  };

  // 登录
  const handleLogin = async () => {
    let { phone, code } = form.getFieldsValue();
    let data = await $login({
      mode: 2,
      username: phone,
      key: code,
    });
    if (data.code === 10000) {
      // 缓存 token
      setToken(
        data.data.id,
        data.data.username,
        data.data.token,
        data.data.expiresIn
      );
      setIsModalOpen(false);
      // 跳转定制页面
      navigate("/custom");
    } else if (data.code === 30020) {
      setFieldError(form, "code", data.msg);
    } else {
      message.error(data.msg, 5);
    }
  };

  // 取消登录
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      className="index"
      style={{
        position: "relative",
      }}
    >
      <div className="bg-gradient-header-1"></div>
      <div className="bg0"></div>
      {/* <div className="bg1"></div>
      <div className="bg2"></div>
      <div className="bg-gradient-1-2"></div>
      <div className="bg3"></div> */}
      <div className="bg-gradient-3-footer"></div>

      <Button
        className="custom"
        type="primary"
        size={isMobile() ? "middle" : "large"}
        onClick={handleCustom}
        ghost
      >
        开始定制
      </Button>
      <Modal
        className="login-modal"
        width={isMobile() ? 318 : 448}
        // maskClosable={false}
        open={isModalOpen}
        okText="登录 / 注册"
        onCancel={handleCancel}
        footer={[
          <Button
            className="login-button"
            key="login"
            type="primary"
            block
            onClick={handleLogin}
            size={isMobile() ? "middle" : "large"}
            disabled={isValidPhone && isValidCode ? false : true}
          >
            登录 / 注册
          </Button>,
        ]}
      >
        {isMobile() ? (
          <h3>万物可灵，欢迎您使用！</h3>
        ) : (
          <h2>万物可灵，欢迎您使用！</h2>
        )}
        <br />
        <Form
          form={form}
          layout="vertical"
          size={isMobile() ? "middle" : "large"}
        >
          <Form.Item
            name="phone"
            label={isMobile() ? "" : "手机号"}
            onChange={handlePhoneChange}
          >
            <Input placeholder="请输入手机号" disabled={isSmsSuccess} />
          </Form.Item>
          <Form.Item
            name="code"
            label={isMobile() ? "" : "验证码"}
            onChange={handleCodeChange}
          >
            <Space.Compact
              style={{
                width: "100%",
              }}
            >
              <Input placeholder="请输入验证码" />
              <Button onClick={handleSmsSend} disabled={isSmsDisabled}>
                {isSmsSuccess ? smsCountdownMsg : "发送验证码"}
              </Button>
            </Space.Compact>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
