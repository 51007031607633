const hostname = window.location.hostname;

export const ossVoiceURL =
  "https://wwkl-voice-type.oss-cn-hangzhou.aliyuncs.com/";

// OSS
export const ossBaseURL =
  hostname === "localhost" || hostname === "127.0.0.1"
    ? "https://wwkl-web-custom-dev.oss-cn-hangzhou.aliyuncs.com/"
    : "https://wwkl-web-custom-prod.oss-cn-hangzhou.aliyuncs.com/";

// API
export const baseURL =
  hostname === "localhost" || hostname === "127.0.0.1"
    ? "http://localhost:8080"
    : "https://api.52agent.com";

export const customImageURL = baseURL + "/custom/image";
export const customVoiceURL = baseURL + "/custom/voice";
export const customKnowledgeURL = baseURL + "/custom/knowledge";

// 人设定制
export const role = [
  "《代号鸢》孙策",
  "《恋与深空》黎深",
  "《斗罗大陆》唐三",
  "《一人之下》张楚岚",
  "文俊辉（SEVENTEEN）",
  "张真源（TNT）",
  "海尔兄弟（海尔）",
  "旺仔（旺旺）",
  "银行理财顾问",
  "保险销售顾问",
];

// 模板声音
export const voiceType = [
  {
    value: "zh_female_shuangkuaisisi_moon_bigtts.mp3",
    name: "爽快思思",
  },
  {
    value: "zh_male_wennuanahu_moon_bigtts.mp3",
    name: "温暖阿虎",
  },
  {
    value: "zh_female_linjianvhai_moon_bigtts.mp3",
    name: "邻家女孩",
  },
  {
    value: "zh_male_shaonianzixin_moon_bigtts.mp3",
    name: "少年梓辛",
  },
  {
    value: "zh_male_yuanboxiaoshu_moon_bigtts.mp3",
    name: "渊博小叔",
  },
  {
    value: "zh_male_yangguangqingnian_moon_bigtts.mp3",
    name: "阳光青年",
  },
  {
    value: "zh_male_jingqiangkanye_moon_bigtts.mp3",
    name: "京腔侃爷",
  },
  {
    value: "zh_female_wanwanxiaohe_moon_bigtts.mp3",
    name: "湾湾小何",
  },
  {
    value: "zh_female_wanqudashu_moon_bigtts.mp3",
    name: "湾区大叔",
  },
  {
    value: "zh_female_daimengchuanmei_moon_bigtts.mp3",
    name: "呆萌川妹",
  },
  {
    value: "zh_male_guozhoudege_moon_bigtts.mp3",
    name: "广州德哥",
  },
  {
    value: "zh_male_beijingxiaoye_moon_bigtts.mp3",
    name: "北京小爷",
  },
  {
    value: "zh_male_haoyuxiaoge_moon_bigtts.mp3",
    name: "浩宇小哥",
  },
  {
    value: "zh_male_guangxiyuanzhou_moon_bigtts.mp3",
    name: "广西远舟",
  },
  {
    value: "zh_female_meituojieer_moon_bigtts.mp3",
    name: "妹坨洁儿",
  },
  {
    value: "zh_male_yuzhouzixuan_moon_bigtts.mp3",
    name: "豫州子轩",
  },
  {
    value: "zh_female_gaolengyujie_moon_bigtts.mp3",
    name: "高冷御姐",
  },
  {
    value: "zh_male_aojiaobazong_moon_bigtts.mp3",
    name: "傲娇霸总",
  },
  {
    value: "zh_female_meilinvyou_moon_bigtts.mp3",
    name: "魅力女友",
  },
  {
    value: "zh_male_shenyeboke_moon_bigtts.mp3",
    name: "深夜播客",
  },
  {
    value: "zh_female_sajiaonvyou_moon_bigtts.mp3",
    name: "柔美女友",
  },
  {
    value: "zh_female_yuanqinvyou_moon_bigtts.mp3",
    name: "撒娇学妹",
  },
  {
    value: "ICL_zh_female_bingruoshaonv_tob.mp3",
    name: "病弱少女",
  },
  {
    value: "ICL_zh_female_huoponvhai_tob.mp3",
    name: "活泼女孩",
  },
  {
    value: "ICL_zh_female_heainainai_tob.mp3",
    name: "和蔼奶奶",
  },
  {
    value: "ICL_zh_female_linjuayi_tob.mp3",
    name: "邻居阿姨",
  },
  {
    value: "zh_female_wenrouxiaoya_moon_bigtts.mp3",
    name: "温柔小雅",
  },
  {
    value: "zh_female_tianmeixiaoyuan_moon_bigtts.mp3",
    name: "甜美小源",
  },
  {
    value: "zh_female_qingchezizi_moon_bigtts.mp3",
    name: "清澈梓梓",
  },
  {
    value: "zh_male_dongfanghaoran_moon_bigtts.mp3",
    name: "东方浩然",
  },
  {
    value: "zh_male_jieshuoxiaoming_moon_bigtts.mp3",
    name: "解说小明",
  },
  {
    value: "zh_female_kailangjiejie_moon_bigtts.mp3",
    name: "开朗姐姐",
  },
  {
    value: "zh_male_linjiananhai_moon_bigtts.mp3",
    name: "邻家男孩",
  },
  {
    value: "zh_female_tianmeiyueyue_moon_bigtts.mp3",
    name: "甜美悦悦",
  },
  {
    value: "zh_female_xinlingjitang_moon_bigtts.mp3",
    name: "心灵鸡汤",
  },
  {
    value: "zh_female_cancan_mars_bigtts.mp3",
    name: "灿灿",
  },
  {
    value: "zh_male_tiancaitongsheng_mars_bigtts.mp3",
    name: "天才童声",
  },
  {
    value: "zh_male_naiqimengwa_mars_bigtts.mp3",
    name: "奶气萌娃",
  },
  {
    value: "zh_male_sunwukong_mars_bigtts.mp3",
    name: "猴哥",
  },
  {
    value: "zh_male_xionger_mars_bigtts.mp3",
    name: "熊二",
  },
  {
    value: "zh_female_peiqi_mars_bigtts.mp3",
    name: "佩奇猪",
  },
  {
    value: "zh_female_zhixingnvsheng_mars_bigtts.mp3",
    name: "知性女声",
  },
  {
    value: "zh_female_qingxinnvsheng_mars_bigtts.mp3",
    name: "清新女声",
  },
  {
    value: "zh_male_changtianyi_mars_bigtts.mp3",
    name: "悬疑解说",
  },
  {
    value: "zh_female_popo_mars_bigtts.mp3",
    name: "婆婆",
  },
  {
    value: "zh_female_wuzetian_mars_bigtts.mp3",
    name: "武则天",
  },
  {
    value: "zh_female_linjia_mars_bigtts.mp3",
    name: "邻家小妹",
  },
  {
    value: "zh_female_shaoergushi_mars_bigtts.mp3",
    name: "少儿故事",
  },
  {
    value: "zh_male_silang_mars_bigtts.mp3",
    name: "四郎",
  },
  {
    value: "zh_female_gujie_mars_bigtts.mp3",
    name: "顾姐",
  },
  {
    value: "zh_female_yingtaowanzi_mars_bigtts.mp3",
    name: "樱桃丸子",
  },
  {
    value: "zh_male_jieshuonansheng_mars_bigtts.mp3",
    name: "磁性解说男声",
  },
  {
    value: "zh_female_jitangmeimei_mars_bigtts.mp3",
    name: "鸡汤妹妹",
  },
  {
    value: "zh_male_chunhui_mars_bigtts.mp3",
    name: "广告解说",
  },
  {
    value: "zh_male_qingshuangnanda_mars_bigtts.mp3",
    name: "清爽男大",
  },
  {
    value: "zh_female_tiexinnvsheng_mars_bigtts.mp3",
    name: "贴心女声",
  },
  {
    value: "zh_female_qiaopinvsheng_mars_bigtts.mp3",
    name: "俏皮女声",
  },
  {
    value: "zh_female_mengyatou_mars_bigtts.mp3",
    name: "萌丫头",
  },
  {
    value: "zh_male_ruyaqingnian_mars_bigtts.mp3",
    name: "儒雅青年",
  },
  {
    value: "zh_male_baqiqingshu_mars_bigtts.mp3",
    name: "霸气青叔",
  },
  {
    value: "zh_male_qingcang_mars_bigtts.mp3",
    name: "擎苍",
  },
  {
    value: "zh_male_yangguangqingnian_mars_bigtts.mp3",
    name: "活力小哥",
  },
  {
    value: "zh_female_gufengshaoyu_mars_bigtts.mp3",
    name: "古风少御",
  },
  {
    value: "zh_female_wenroushunv_mars_bigtts.mp3",
    name: "温柔淑女",
  },
  {
    value: "ICL_zh_male_lvchaxiaoge_tob.mp3",
    name: "绿茶小哥",
  },
  {
    value: "ICL_zh_female_zhixingwenwan_tob.mp3",
    name: "知性温婉",
  },
  {
    value: "ICL_zh_female_jiaoruoluoli_tob.mp3",
    name: "娇弱萝莉",
  },
  {
    value: "ICL_zh_male_lengdanshuli_tob.mp3",
    name: "冷淡疏离",
  },
  {
    value: "ICL_zh_male_nuanxintitie_tob.mp3",
    name: "暖心体贴",
  },
  {
    value: "ICL_zh_male_hanhoudunshi_tob.mp3",
    name: "憨厚敦实",
  },
  {
    value: "ICL_zh_female_wenrouwenya_tob.mp3",
    name: "温柔文雅",
  },
  {
    value: "ICL_zh_male_aiqilingren_tob.mp3",
    name: "傲气凌人",
  },
  {
    value: "ICL_zh_male_kailangqingkuai_tob.mp3",
    name: "开朗轻快",
  },
  {
    value: "ICL_zh_female_huopodiaoman_tob.mp3",
    name: "活泼刁蛮",
  },
  {
    value: "ICL_zh_male_guzhibingjiao_tob.mp3",
    name: "固执病娇",
  },
  {
    value: "ICL_zh_male_huoposhuanglang_tob.mp3",
    name: "活泼爽朗",
  },
  {
    value: "ICL_zh_male_sajiaonianren_tob.mp3",
    name: "撒娇粘人",
  },
  {
    value: "ICL_zh_female_aomanjiaosheng_tob.mp3",
    name: "傲慢娇声",
  },
  {
    value: "ICL_zh_male_xiaosasuixing_tob.mp3",
    name: "潇洒随性",
  },
  {
    value: "ICL_zh_male_fuheigongzi_tob.mp3",
    name: "腹黑公子",
  },
  {
    value: "ICL_zh_male_guiyishenmi_tob.mp3",
    name: "诡异神秘",
  },
  {
    value: "ICL_zh_male_ruyacaijun_tob.mp3",
    name: "儒雅才俊",
  },
  {
    value: "ICL_zh_male_bingjiaobailian_tob.mp3",
    name: "病娇白莲",
  },
  {
    value: "ICL_zh_male_zhengzhiqingnian_tob.mp3",
    name: "正直青年",
  },
  {
    value: "ICL_zh_male_shuaizhenxiaohuo_tob.mp3",
    name: "率真小伙",
  },
  {
    value: "ICL_zh_female_jiaohannvwang_tob.mp3",
    name: "娇憨女王",
  },
  {
    value: "ICL_zh_female_bingjiaomengmei_tob.mp3",
    name: "病娇萌妹",
  },
  {
    value: "ICL_zh_male_qingsenaigou_tob.mp3",
    name: "青涩小生",
  },
  {
    value: "ICL_zh_male_chunzhenxuedi_tob.mp3",
    name: "纯真学弟",
  },
  {
    value: "ICL_zh_female_nuanxinxuejie_tob.mp3",
    name: "暖心学姐",
  },
  {
    value: "ICL_zh_female_keainvsheng_tob.mp3",
    name: "可爱女生",
  },
  {
    value: "ICL_zh_female_chengshujiejie_tob.mp3",
    name: "成熟姐姐",
  },
  {
    value: "ICL_zh_female_bingjiaojiejie_tob.mp3",
    name: "病娇姐姐",
  },
  {
    value: "ICL_zh_male_youroubangzhu_tob.mp3",
    name: "优柔帮主",
  },
  {
    value: "ICL_zh_male_yourougongzi_tob.mp3",
    name: "优柔公子",
  },
  {
    value: "ICL_zh_female_wumeiyujie_tob.mp3",
    name: "妩媚御姐",
  },
  {
    value: "ICL_zh_female_tiaopigongzhu_tob.mp3",
    name: "调皮公主",
  },
  {
    value: "ICL_zh_female_aojiaonvyou_tob.mp3",
    name: "傲娇女友",
  },
  {
    value: "ICL_zh_male_tiexinnanyou_tob.mp3",
    name: "贴心男友",
  },
  {
    value: "ICL_zh_male_shaonianjiangjun_tob.mp3",
    name: "少年将军",
  },
  {
    value: "ICL_zh_female_tiexinnvyou_tob.mp3",
    name: "贴心女友",
  },
  {
    value: "ICL_zh_male_bingjiaogege_tob.mp3",
    name: "病娇哥哥",
  },
  {
    value: "ICL_zh_male_xuebanantongzhuo_tob.mp3",
    name: "学霸男同桌",
  },
  {
    value: "ICL_zh_male_youmoshushu_tob.mp3",
    name: "幽默叔叔",
  },
  {
    value: "ICL_zh_female_xingganyujie_tob.mp3",
    name: "性感御姐",
  },
  {
    value: "ICL_zh_female_jiaxiaozi_tob.mp3",
    name: "假小子",
  },
  {
    value: "ICL_zh_male_lengjunshangsi_tob.mp3",
    name: "冷峻上司",
  },
  {
    value: "ICL_zh_male_wenrounantongzhuo_tob.mp3",
    name: "温柔男同桌",
  },
  {
    value: "ICL_zh_male_bingjiaodidi_tob.mp3",
    name: "病娇弟弟",
  },
  {
    value: "ICL_zh_male_youmodaye_tob.mp3",
    name: "幽默大爷",
  },
  {
    value: "ICL_zh_male_aomanshaoye_tob.mp3",
    name: "傲慢少爷",
  },
  {
    value: "ICL_zh_male_shenmifashi_tob.mp3",
    name: "神秘法师",
  },
];
