import { request } from "../utils/axios/request";

// 创建订单
export const $create = async (params) => {
  return await request.post("/order/create", { ...params });
};

// 订单详情
export const $detail = async (params) => {
  return await request.get("/order/detail/" + params);
};

// 订单确认/支付
export const $confirm = async (params) => {
  return await request.post("/order/confirm", { ...params });
};

// 订单列表
export const $list = async (params) => {
  return await request.post("/order/list", { ...params });
};
