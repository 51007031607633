import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import {
  message,
  Form,
  Input,
  Button,
  Image,
  Tag,
  List,
  Divider,
  Radio,
  Drawer,
  Space,
  // Row,
  // Col,
  Cascader,
} from "antd";
import { RightOutlined, PlusOutlined, CheckOutlined } from "@ant-design/icons";
import { buildState } from "./buildOrderState";
import { $detail, $confirm } from "../../../api/orderAPI";
import { $list, $add } from "../../../api/addressAPI";
import { LOCATION } from "../../../config/location";
import { mobilePattern } from "../../../utils/regex";
import { setFieldError, clearFieldError } from "../../../utils/form";
import "../common.scss";
import { isMobile } from "../../../utils/func";

export default function Detail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [formAddress] = Form.useForm();
  // 订单信息
  const [data, setData] = useState({
    type: 1,
    type_name: null,
    touch: null,
    image: null,
    view: null,
    voice_type: null,
    voice: null,
    audio: null,
    role: [],
    knowledge: { file: [], url: null },
    quantity: null,
    price: null,
    amount: null,
    aid: null,
    name: null,
    mobile: null,
    location: null,
    address: null,
  });
  // 抽屉开关
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // 新增收货地址开关
  const [isAddressAddOpen, setIsAddressAddOpen] = useState(false);
  // 收货地址列表
  const [addressList, setAddressList] = useState();
  // 已选择的收货地址
  const [address, setAddress] = useState({
    id: null,
    name: null,
    mobile: null,
    address: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      const code = searchParams.get("code");
      if (!code) {
        navigate("/404");
      }
      let data = await $detail(code);
      if (data.code === 10000) {
        // console.log(data.data);
        // let newState = buildState(data.data);
        // console.log(newState);
        // setData(newState);
        setData(buildState(data.data));
      } else {
        navigate("/404");
      }
    };
    fetchData();
  }, [searchParams, navigate]);

  // 收货地址抽屉打开事件处理
  const handleOpenDrawer = async () => {
    clearFieldError(form, "address");
    setIsDrawerOpen(true);
    let data = await $list();
    if (data.code === 10000) {
      setAddressList(data.data);
    }
  };

  // 收货地址选择事件处理
  const handleAddressChecked = (e) => {
    setAddress({
      id: e.currentTarget.getAttribute("data-id"),
      name: e.currentTarget.getAttribute("data-name"),
      mobile: e.currentTarget.getAttribute("data-mobile"),
      address: e.currentTarget.getAttribute("data-address"),
    });
    setIsDrawerOpen(false);
  };

  // 新增并使用收货地址
  const handleFormAddressSubmit = async () => {
    let validPass = true;
    validPass = validName() ? validPass : false;
    validPass = validMobile() ? validPass : false;
    validPass = validLocation() ? validPass : false;
    validPass = validAddress() ? validPass : false;
    if (validPass) {
      // console.log(postData);
      let data = await $add({
        name: formAddress.getFieldValue("name"),
        mobile: formAddress.getFieldValue("mobile"),
        location: formAddress.getFieldValue("location").join(","),
        address: formAddress.getFieldValue("address"),
      });
      if (data.code === 10000) {
        setAddress({
          id: data.data,
          name: formAddress.getFieldValue("name"),
          mobile: formAddress.getFieldValue("mobile"),
          address: formAddress.getFieldValue("address"),
        });
        formAddress.resetFields();
        setIsAddressAddOpen(false);
        setIsDrawerOpen(false);
      } else {
        message.error(data.msg);
      }
    }
  };

  const validName = () => {
    if (!formAddress.getFieldValue("name")) {
      setFieldError(formAddress, "name", "请输入联系人姓名");
      return false;
    }
    return true;
  };

  const validMobile = () => {
    if (!mobilePattern.test(formAddress.getFieldValue("mobile"))) {
      setFieldError(formAddress, "mobile", "请输入正确的手机号");
      return false;
    }
    return true;
  };

  const validLocation = () => {
    if (!formAddress.getFieldValue("location")) {
      setFieldError(formAddress, "location", "请选择联系人所在城市");
      return false;
    }
    return true;
  };

  const validAddress = () => {
    if (!formAddress.getFieldValue("address")) {
      setFieldError(formAddress, "address", "请输入门牌号");
      return false;
    }
    return true;
  };

  const handlePay = async () => {
    const code = searchParams.get("code");
    if (!code) {
      navigate("/404");
    }
    if (data.status === 1 && !address.id) {
      setFieldError(form, "address", "请选择收货地址");
      return;
    }
    let resp = await $confirm({
      code: code,
      aid: data.status === 1 ? address.id : data.aid,
      platform: form.getFieldValue("platform"),
      endpoint: isMobile() ? 2 : 1,
    });
    if (resp.code === 10000) {
      window.location.href = resp.data;
    } else {
      message.error(resp.msg);
    }
  };

  return (
    <div className="form-wrap">
      <Form
        className="form-right"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 13,
          offset: 1,
        }}
        layout="horizontal"
        form={form}
        initialValues={{
          platform: 1,
        }}
      >
        <Form.Item label="定制类型">{data.type_name}</Form.Item>

        <Form.Item label="触碰交互">{data.touch}</Form.Item>

        <Form.Item label="形象定制" valuePropName="fileList">
          <div className="view-wrap">
            <Image name="view" height="61%" width="94%" src={data.view} />
          </div>
        </Form.Item>

        <Form.Item
          label="模板声音"
          style={{ display: data.type === 2 ? "" : "none" }}
        >
          {data.voice_type}
        </Form.Item>

        <Form.Item
          label="声音克隆"
          style={{ display: data.type === 3 ? "" : "none" }}
        >
          <div>
            <audio controls src={data.audio} />
          </div>
        </Form.Item>

        <Form.Item
          label="人设定制"
          style={{ display: data.type !== 1 ? "" : "none" }}
        >
          {data.role.map((item) => (
            <Tag bordered={false} color="cyan" key={item}>
              {item}
            </Tag>
          ))}
        </Form.Item>

        <Form.Item
          label="知识库定制（本地）"
          style={{ display: data.type !== 1 ? "" : "none" }}
        >
          <List
            size="small"
            dataSource={data.knowledge.file}
            bordered={true}
            renderItem={(item) => (
              <List.Item>
                <Link to={item.src}>{item.key}</Link>
              </List.Item>
            )}
          />
        </Form.Item>

        <Form.Item
          label="知识库定制（在线）"
          style={{ display: data.type !== 1 ? "" : "none" }}
        >
          <Input.TextArea
            autoSize={{
              minRows: 5,
              maxRows: 10,
            }}
            value={data.knowledge.url}
            disabled
          />
        </Form.Item>

        <Form.Item label="定制数量">
          <span>{data.quantity}</span>
          <span>&nbsp;套</span>
          <span style={{ fontSize: "12px", color: "gray" }}>
            &nbsp;（¥{data.price}/套）
          </span>
        </Form.Item>

        <Form.Item label="总价">
          <span style={{ fontWeight: "bold" }}>¥</span>
          <span style={{ fontSize: "18px", fontWeight: "bold" }}>
            {data.amount}
          </span>
        </Form.Item>

        <Divider dashed />

        <Form.Item
          label="收货地址"
          name="address"
          required={data.status === 1 ? true : false}
        >
          <div
            style={{ cursor: data.status === 1 ? "pointer" : "" }}
            onClick={data.status === 1 ? handleOpenDrawer : void 0}
          >
            {/* orderStatus == 1 */}
            <div
              style={{
                display: data.status === 1 && !address.id ? "" : "none",
              }}
            >
              <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                请选择收货地址 <RightOutlined />
              </span>
            </div>
            <div
              style={{ display: data.status === 1 && address.id ? "" : "none" }}
            >
              <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                {address.address} <RightOutlined />
              </span>
              <br />
              <span style={{ color: "gray" }}>
                <span>{address.name}</span>&nbsp;<span>{address.mobile}</span>
              </span>
            </div>
            {/* orderStatus == 2|3 */}
            <div style={{ display: data.status !== 1 ? "" : "none" }}>
              <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                {data.address}
              </span>
              <br />
              <span style={{ color: "gray" }}>
                <span>{data.name}</span>&nbsp;<span>{data.mobile}</span>
              </span>
            </div>
          </div>
        </Form.Item>

        <Form.Item
          label="支付方式"
          name="platform"
          required={data.status === 1 ? true : false}
        >
          <Radio.Group disabled={data.status === 1 ? false : true}>
            <Radio value={1}>支付宝&nbsp;</Radio>
            {/* <Radio value={2}>微信&nbsp;</Radio> */}
          </Radio.Group>
        </Form.Item>

        <Button
          className="operation-button"
          size="large"
          type="primary"
          block
          onClick={handlePay}
          style={{ display: data.status === 1 ? "" : "none" }}
        >
          去 支 付
        </Button>
        <Button
          className="operation-button"
          size="large"
          type="primary"
          block
          onClick={handlePay}
          style={{ display: data.status === 2 ? "" : "none" }}
        >
          继续支付
        </Button>
        <Button
          className="operation-button"
          size="large"
          block
          // onClick={() => navigate("/")}
          style={{ display: data.status === 3 ? "" : "none" }}
          color="primary"
          variant="dashed"
        >
          <CheckOutlined />
          已支付
        </Button>
      </Form>

      <Drawer
        title="请选择收货地址"
        open={isDrawerOpen}
        onClose={() => {
          formAddress.resetFields();
          setIsDrawerOpen(false);
          setIsAddressAddOpen(false);
        }}
        width={478}
        extra={
          <Space>
            <Button style={{ display: "none" }} />
            <Button
              style={{ display: isAddressAddOpen ? "" : "none" }}
              onClick={() => setIsAddressAddOpen(false)}
            >
              返回
            </Button>
            <Button
              type="primary"
              style={{ display: isAddressAddOpen ? "" : "none" }}
              onClick={handleFormAddressSubmit}
            >
              新增并使用
            </Button>
            <Button
              type="primary"
              style={{ display: !isAddressAddOpen ? "" : "none" }}
              onClick={() => setIsAddressAddOpen(true)}
            >
              <PlusOutlined />
              新增收货地址
            </Button>
          </Space>
        }
      >
        {/* 收货地址列表 */}
        <List
          size="small"
          dataSource={addressList}
          bordered={false}
          style={{ display: !isAddressAddOpen ? "" : "none" }}
          renderItem={(item) => (
            <List.Item>
              <div
                style={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={handleAddressChecked}
                data-id={item.id}
                data-address={item.address}
                data-name={item.name}
                data-mobile={item.mobile}
              >
                <div>
                  <Radio
                    key={item.id}
                    // eslint-disable-next-line eqeqeq
                    checked={address.id == item.id ? true : false}
                  >
                    <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                      {item.address}
                    </span>
                    <br />
                    <span style={{ color: "gray" }}>
                      <span>{item.name}</span>&nbsp;<span>{item.mobile}</span>
                    </span>
                  </Radio>
                </div>
                <div>
                  <RightOutlined />
                </div>
              </div>
            </List.Item>
          )}
        />
        {/* 新增收货地址 */}
        <Form
          layout="vertical"
          style={{ display: isAddressAddOpen ? "" : "none" }}
          clearOnDestroy={true}
          form={formAddress}
          size="large"
        >
          <Form.Item label="联系人" name="name" required>
            <Input
              placeholder="收货人的姓名"
              onChange={() => clearFieldError(formAddress, "name")}
            />
          </Form.Item>
          <Form.Item label="手机号" name="mobile" required>
            <Input
              style={{ width: "100%" }}
              addonBefore="+86"
              placeholder="收货人的手机号码"
              onChange={() => clearFieldError(formAddress, "mobile")}
            />
          </Form.Item>
          <Form.Item label="所在城市" name="location" required>
            <Cascader
              options={LOCATION}
              placeholder="收货人所在的城市"
              onChange={() => clearFieldError(formAddress, "location")}
            />
          </Form.Item>
          <Form.Item label="门牌号" name="address" required>
            <Input
              placeholder="详细地址。如，1幢1单元1室"
              onChange={() => clearFieldError(formAddress, "address")}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}
