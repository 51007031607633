import { BrowserRouter, Routes, Route } from "react-router-dom";
import zhCN from "antd/es/locale/zh_CN";
import { ConfigProvider } from "antd";

import NotFound from "./view/Error/NotFound/NotFound";
import BaseLayout from "./view/Layout/BaseLayout";
import OrderLayout from "./view/Order/OrderLayout";
import Index from "./view/Index/Index";
import Custom from "./view/Order/Custom/Custom";
import Detail from "./view/Order/Detail/Detail";
import List from "./view/Order/List/List";
import ListMobile from "./view/Order/List/ListMobile";
import { isMobile } from "./utils/func";

import "./App.css";

function App() {
  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        token: {
          // colorPrimary: "rgb(220, 90, 151)",
          colorPrimary: "#e0529c",
          fontFamily: "zhuyuan",
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route element={<BaseLayout />}>
            <Route path="/" element={<Index />} />
            <Route element={<OrderLayout />}>
              <Route path="/custom" element={<Custom />} />
              <Route path="/detail" element={<Detail />} />
              <Route
                path="/list"
                element={isMobile() ? <ListMobile /> : <List />}
              />
            </Route>
          </Route>
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
