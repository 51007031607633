import { Tag } from "antd";
import { voiceType, ossBaseURL } from "../../../config/config";

export function buildState(data) {
  data.type_name = parseType(data.type);
  data.touch = parseTouch(data.touch);
  data.image = buildSrc(data.image);
  // todo: 永祥自己的服务器返回的直接是 url，后期可能需要改造成 oss，如下注释
  // data.view = buildSrc(data.view);
  data.voice_type = parseVoiceType(data.voice_type);
  data.voice = buildSrc(data.voice);
  data.audio = buildSrc(data.audio);
  data.role = parseRole(data.role);
  data.knowledge = parseKnowledge(data.knowledge);
  return data;
}

// 解析知识库
export function parseKnowledge(knowledge) {
  let temp = { file: [], url: "" };
  if (knowledge.file.length > 0) {
    knowledge.file.forEach((item) => {
      temp.file.push({
        id: item.id,
        key: item.source.split("/")[1],
        src: buildSrc(item.source),
      });
    });
  }
  if (knowledge.url.length > 0) {
    knowledge.url.forEach((item, index) => {
      if (index !== knowledge.url.length - 1) {
        temp.url += item.source + ",";
      } else {
        temp.url += item.source;
      }
    });
  }
  return temp;
}

// 解析人设
export function parseRole(role) {
  if (!role) {
    return [];
  }
  return role.split(",");
}

// 解析模板声音
export function parseVoiceType(type) {
  if (!type) {
    return null;
  }
  let voice_type = null;
  voiceType.forEach((item) => {
    if (item.value === type) {
      voice_type = item.name;
    }
  });
  return <Tag color="cyan-inverse">{voice_type}</Tag>;
}

// 解析触碰交互
export function parseTouch(touch) {
  return touch === 2 ? (
    <Tag color="orange">有触碰交互</Tag>
  ) : (
    <Tag color="blue">无触碰交互</Tag>
  );
}

// 解析定制类型
export function parseType(type) {
  switch (type) {
    case 1:
      return <Tag color="magenta">普通版</Tag>;
    case 2:
      return <Tag color="geekblue">模板声音AI版</Tag>;
    case 3:
      return <Tag color="purple">声音克隆AI版</Tag>;
    default:
      return null;
  }
}

// 构建资源链接
export function buildSrc(key) {
  return key ? ossBaseURL + key : null;
}
