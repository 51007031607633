import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { Layout, Menu, Image, Space, Button } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  SnippetsOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { isIndex, isMobile, removeToken } from "../../utils/func";

import "./baseLayout.scss";

const { Header, Content, Footer } = Layout;

export default function BaseLayout() {
  const navigate = useNavigate();

  const items = [
    {
      label: "个人中心",
      key: "center",
      icon: <UserOutlined />,
      children: [
        {
          label: "前往定制",
          key: "custom",
          icon: <FormOutlined />,
          onClick: () => {
            navigate("/custom");
          },
        },
        {
          label: "我的订单",
          key: "order",
          icon: <SnippetsOutlined />,
          onClick: () => {
            navigate("/list");
          },
        },
        {
          label: "退出登录",
          key: "personal",
          icon: <LogoutOutlined />,
          onClick: () => {
            removeToken();
            navigate("/");
          },
        },
      ],
    },
  ];

  return (
    <Layout>
      <Header className="header">
        <div className="logo">
          <Image
            src={process.env.PUBLIC_URL + "assets/logo.jpg"}
            height={isMobile() ? 30 : 35}
            width={isMobile() ? 144 : 168}
            preview={false}
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Menu
          className="menu"
          mode="horizontal"
          items={items}
          style={{ display: localStorage.getItem("token") ? "" : "none" }}
        />
      </Header>
      <Content className="content">
        <Outlet />
      </Content>
      <Footer
        style={{
          textAlign: "center",
          backgroundColor: "white",
          padding: "16px",
          display: isIndex() ? "" : "none",
        }}
      >
        <Space direction={isMobile() ? "vertical" : "horizontal"} wrap>
          <span>万物可灵(杭州)科技有限公司</span>
          <Button
            color="default"
            variant="link"
            href="tel:4008-123-002"
            style={{ padding: 0, height: "1px" }}
          >
            咨询电话: 4008-123-002
          </Button>
          <Button
            color="default"
            variant="link"
            href="https://beian.miit.gov.cn"
            target="_blank"
            style={{ padding: 0, height: "1px" }}
          >
            浙ICP备2024129576号-1
          </Button>
        </Space>
      </Footer>
    </Layout>
  );
}
