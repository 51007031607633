import { React, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { Layout } from "antd";
import "./orderLayout.scss";

const { Sider, Content } = Layout;

export default function OrderLayout() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const expiresIn = localStorage.getItem("expiresIn");
    if (!token || !expiresIn || expiresIn < Math.floor(Date.now() / 1000)) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Layout>
      <Sider
        width={440}
        breakpoint="xl"
        collapsedWidth={0}
        trigger={null}
        className="sider"
      >
        <div className="sider-wrap"></div>
      </Sider>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
}
