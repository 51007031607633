export function setFieldError(form, field, error) {
  form.setFields([
    {
      name: field,
      errors: [error],
    },
  ]);
}

export function clearFieldError(form, field) {
  form.setFields([
    {
      name: field,
      errors: [],
    },
  ]);
}
