import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Table, Image, Tag, Empty, Button } from "antd";
import { ossBaseURL } from "../../../config/config";
import { $list } from "../../../api/orderAPI";
import { isMobile } from "../../../utils/func";
import "./list.scss";

const columns = [
  {
    title: "原图",
    dataIndex: "image",
    render: (image) => <Image className="image" src={ossBaseURL + image} />,
    // width: "88px",
  },
  {
    title: "订单编号",
    dataIndex: "code",
    render: (code) => (
      <Link
        to={"/detail?code=" + code}
        target={isMobile() ? "_self" : "_blank"}
      >
        {code}
      </Link>
    ),
  },
  {
    title: "定制类型",
    dataIndex: "type",
    render: (type) => {
      if (type === 1) {
        return <Tag color="">普通版</Tag>;
      }
      if (type === 2) {
        return <Tag color="">模板声音AI版</Tag>;
      }
      if (type === 3) {
        return <Tag color="">声音克隆AI版</Tag>;
      }
    },
  },
  {
    title: "触碰交互",
    dataIndex: "touch",
    render: (touch) => {
      return touch === 2 ? (
        <Tag color="">有触碰</Tag>
      ) : (
        <Tag color="">无触碰</Tag>
      );
    },
  },
  {
    title: "单价",
    dataIndex: "price",
    render: (price) => "¥" + price + "/套",
  },
  {
    title: "数量",
    dataIndex: "quantity",
  },
  {
    title: "总价",
    dataIndex: "amount",
    render: (amount) => "¥" + amount,
  },
  {
    title: "状态",
    dataIndex: "status",
    render: (status) => {
      if (status === 1) {
        return (
          <Tag color="blue" bordered={false}>
            待确认
          </Tag>
        );
      }
      if (status === 2) {
        return (
          <Tag color="volcano" bordered={false}>
            待支付
          </Tag>
        );
      }
      if (status === 3) {
        return (
          <Tag color="green" bordered={false}>
            已支付
          </Tag>
        );
      }
    },
  },
  {
    title: "操作",
    key: "action",
    render: (_, record) => (
      <Link
        to={"/detail?code=" + record.code}
        target={isMobile() ? "_self" : "_blank"}
      >
        详情
      </Link>
    ),
  },
];

export default function List() {
  const navigate = useNavigate();
  const [list, setList] = useState();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 7,
    },
  });

  const fetchData = async () => {
    setLoading(true);
    let data = await $list({
      current: tableParams.pagination.current,
      size: tableParams.pagination.pageSize,
    });
    if (data.code === 10000) {
      // console.log(data.data);
      if (data.data === null) {
        setLoading(false);
        return;
      }
      setList(data.data.list);
      setLoading(false);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data.data.page.total,
        },
      });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableParams.pagination?.current, tableParams.pagination?.pageSize]);

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
  };

  return (
    <Table
      className="data-wrap"
      columns={columns}
      rowKey={(record) => record.code}
      dataSource={list}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
      locale={{
        emptyText: (
          <Empty description="暂无数据">
            <Button
              type="primary"
              onClick={() => {
                navigate("/custom");
              }}
            >
              前往定制
            </Button>
          </Empty>
        ),
      }}
    />
  );
}
